// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-a-js": () => import("./../../../src/pages/a.js" /* webpackChunkName: "component---src-pages-a-js" */),
  "component---src-pages-b-js": () => import("./../../../src/pages/b.js" /* webpackChunkName: "component---src-pages-b-js" */),
  "component---src-pages-c-js": () => import("./../../../src/pages/c.js" /* webpackChunkName: "component---src-pages-c-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

